import React from "react";
import { IonItem, IonLabel } from "@ionic/react";
import BasicPage from "../components/BasicPage";

/**
 * sets the `title` and property hasMenu = false so  the menu for the side
 * drawer is NOT displayed.
 *
 * sets the `backAction` property so the back button appears
 *
 * sets the `renderContent` propety to render the contents of the page
 */
class BookletPage extends React.Component {
  render() {
    const { params } = this.props.match;
    return (
      <BasicPage
        title={`Booklet Page ${params.page_id}`}
        hasMenu
        renderContent={history => {
          return (
            <>
              <IonItem lines="none">
                <IonLabel>{`Booklet Page ${params.page_id}`}</IonLabel>
              </IonItem>
            </>
          );
        }}
      />
    );
  }
}

export default BookletPage;
