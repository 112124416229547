import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onSuccess: function() {
    console.log("service worker installed");
  },
  onUpdate: function() {
    console.log("service worker updated");
  }
});

if (window.matchMedia("(display-mode: standalone)").matches) {
  console.log("display-mode is standalone");
}
