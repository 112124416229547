import React, { Component } from "react";
import { IonButton, IonLabel, IonItem, IonInput } from "@ionic/react";
import BasicPage from "../components/BasicPage";
import "../App.css";
import Validator from "../shared/Validator";
import ItemService from "../shared/ItemService";

/**
 * sets the `title` and property hasMenu = false so  the menu for the side
 * drawer is NOT displayed.
 *
 * sets the `backAction` property so the back button appears
 *
 * sets the `renderContent` propety to render the contents of the page
 */
class ItemEdit extends Component {
  constructor(props) {
    super(props);
    this.itemService = new ItemService();
    this.validator = new Validator();
    this.handleInputChange = this.handleInputChange.bind(this);
    this.state = {
      title: "",
      description: "",
      author: ""
    };
    this.id = parseInt(this.props.match.params.item_id, 10);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState(oldState => {
      return {
        [name]: value
      };
    });
  }

  componentDidMount() {
    this.itemService.getItem(this.id).then(item => {
      this.setState(state => {
        // Important: read `state` instead of `this.state` when updating.
        return item;
      });
    });
  }

  render() {
    return (
      <BasicPage
        title={`Details for Item #${this.id}`}
        backAction={e => {
          this.props.history.goBack();
        }}
        renderContent={history => {
          return (
            <>
              <IonItem>
                <IonLabel>Title</IonLabel>
                <IonInput
                  slot="end"
                  clearInput
                  value={this.state.title}
                  name="title"
                  placeholder="Enter some title"
                  required
                  onIonChange={this.handleInputChange}
                  maxLength="40"
                />
              </IonItem>
              <IonItem>
                <IonLabel>Description</IonLabel>
                <IonInput
                  slot="end"
                  clearInput
                  value={this.state.description}
                  name="description"
                  placeholder="Enter some description"
                  required
                  onIonChange={this.handleInputChange}
                  maxLength="40"
                />
              </IonItem>
              <IonItem lines="none">
                <IonLabel>Author</IonLabel>
                <IonInput
                  slot="end"
                  clearInput
                  value={this.state.author}
                  name="author"
                  type="number"
                  placeholder="Enter a valid author id"
                  required
                  onIonChange={this.handleInputChange}
                />
              </IonItem>

              <IonButton
                color="primary"
                onClick={e => {
                  e.preventDefault();
                  this.itemService.updateItem({ ...this.state, id: this.id });
                  history.push(`/item-detail/${this.id}`);
                }}
              >
                Submit Changes
              </IonButton>
              <IonButton
                color="secondary"
                onClick={e => {
                  e.preventDefault();
                  history.push(`/item-detail/${this.id}`);
                }}
              >
                Cancel
              </IonButton>
              <IonButton
                color="danger"
                onClick={e => {
                  e.preventDefault();
                  this.itemService.deleteItem(this.id);
                  history.push(`/item-list`);
                }}
              >
                Delete
              </IonButton>
            </>
          );
        }}
      />
    );
  }
}

export default ItemEdit;
