import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class Menu extends Component {
  render() {
    const { history, appPages } = this.props;

    return (
      <IonMenu contentId="main">
        <IonHeader>
          <IonToolbar>
            <IonTitle>Menu</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent class="outer-content">
          <IonList>
            {appPages.map((appPage, index) => {
              return (
                <IonMenuToggle key={index} auto-hide="false">
                  <IonItem button onClick={() => history.push(appPage.url)}>
                    <IonIcon slot="start" name={appPage.icon} />
                    <IonLabel>{appPage.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            })}
          </IonList>
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonTitle>Footer</IonTitle>
            <IonButton id="install" style={{ display: "none" }}>
              Install App
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </IonMenu>
    );
  }

  componentDidMount() {
    const installButton = document.getElementById("install");
    let deferredPrompt;

    window.addEventListener("beforeinstallprompt", e => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI notify the user they can add to home screen
      installButton.style.display = "block";
    });

    installButton.addEventListener("click", async () => {
      await deferredPrompt.prompt();
      const choiceResult = await deferredPrompt.userChoice;
      console.log(choiceResult.outcome);
    });

    window.addEventListener("appinstalled", () => {
      installButton.style.display = "none";
    });
  }
}

export default withRouter(Menu);
