import React, { Component } from "react";
import {
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime
} from "@ionic/react";
import BasicPage from "../components/BasicPage";
import ItemService from "../shared/ItemService";

/**
 * sets the `title` and property hasMenu = true so that the menu for the side
 * drawer is displayed
 *
 * sets the `renderContent` propety to render the contents of the page
 */
class ItemList extends Component {
  constructor(props) {
    super(props);
    this.itemService = new ItemService();
    this.onSelect = this.onSelect.bind(this);
    this.state = {};
  }

  componentDidMount() {
    this.itemService.retrieveItems().then(items => {
      this.setState(oldState => {
        // Important: read `oldState` instead of `this.state` when updating.
        console.log("old state", oldState);
        return { items };
      });
    });
  }

  render() {
    const items = this.state.items;
    if (!items) return null;
    return (
      <BasicPage
        title="Item List"
        hasMenu
        renderContent={history => {
          return (
            <>
              <IonList>
                {items.map(item => (
                  <IonItem
                    detail
                    key={item.id}
                    onClick={() => this.onSelect(item.id)}
                  >
                    <IonDatetime
                      slot="start"
                      readonly
                      displayFormat="MMM DD, YYYY HH:mm	"
                      value={item.created_at}
                    />
                    <IonLabel>
                      <h3>{item.title}</h3>
                      <p>{item.description}</p>
                    </IonLabel>
                  </IonItem>
                ))}
              </IonList>
              <IonButton
                color="primary"
                onClick={e => {
                  e.preventDefault();
                  history.push("/item-new");
                }}
              >
                New Item
              </IonButton>
            </>
          );
        }}
      />
    );
  }

  onSelect(itemLink) {
    const { history } = this.props;
    this.itemService.getItem(itemLink).then(item => {
      history.push(`/item-detail/${item.id}`);
    });
  }
}

export default ItemList;
