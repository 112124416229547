import React from "react";
import {
  IonFab,
  IonFabButton,
  IonIcon,
  IonFooter,
  IonHeader,
  IonTitle,
  IonContent,
  IonToolbar,
  IonButton,
  IonButtons,
  IonMenuButton,
} from "@ionic/react";

/**
 * sets the `title` and property hasMenu = false so the menu for the side
 * drawer is NOT displayed.
 *
 * sets the `backAction` property so the back button appears
 *
 * sets the `renderContent` property to render the contents of the page
 */
class LocationMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.map = null;
    this.currentLocation = null;
    this.approximationCircle = null;
  }

  componentDidMount() {
    if (!this.map) {
      const that = this;
      const L = window.L;
      const map = this.map = L.map('location_map').setView([52.5192, 13.4061], 16);
      map.attributionControl.setPosition('bottomleft');

      // the following location data will be read from the database
      let locations = [
        {
          category: 'cafe',
          title: 'My Cafe',
          description: 'My favorite cafe.',
          id: 1,
          latlng: this.getRandomLatLng(map),
        },
        {
          category: 'cafe',
          title: 'My Cafe',
          description: 'My favorite cafe.',
          id: 2,
          latlng: this.getRandomLatLng(map),
        },
        {
          category: 'cafe',
          title: 'My Cafe',
          description: 'My favorite cafe.',
          id: 3,
          latlng: this.getRandomLatLng(map),
        },
        {
          category: 'bar',
          title: 'My Bar',
          description: 'My favorite bar.',
          id: 4,
          latlng: this.getRandomLatLng(map),
        },
        {
          category: 'bar',
          title: 'My Bar',
          description: 'My favorite bar.',
          id: 5,
          latlng: this.getRandomLatLng(map),
        },
        {
          category: 'bar',
          title: 'My Bar',
          description: 'My favorite bar.',
          id: 6,
          latlng: this.getRandomLatLng(map),
        },
        {
          category: 'shop',
          title: 'My Shop',
          description: 'My favorite shop.',
          id: 7,
          latlng: this.getRandomLatLng(map),
        },
        {
          category: 'shop',
          title: 'My Shop',
          description: 'My favorite shop.',
          id: 8,
          latlng: this.getRandomLatLng(map),
        },
        {
          category: 'shop',
          title: 'My Shop',
          description: 'My favorite shop.',
          id: 9,
          latlng: this.getRandomLatLng(map),
        }
      ];
      for (let i=10; i<1000; i++) {
        locations.push({
          category: 'cafe',
          title: 'My Cafe',
          description: 'My favorite cafe.',
          id: i,
          latlng: this.getRandomLatLng(map),
        });
      }
      for (let i=1000; i<2000; i++) {
        locations.push({
          category: 'bar',
          title: 'My Bar',
          description: 'My favorite bar.',
          id: i,
          latlng: this.getRandomLatLng(map),
        });
      }
      for (let i=2000; i<3000; i++) {
        locations.push({
          category: 'shop',
          title: 'My Shop',
          description: 'My favorite shop.',
          id: i,
          latlng: this.getRandomLatLng(map),
        });
      }

      L.tileLayer('https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}{r}.png', {
        attribution: '<a href="https://wikimediafoundation.org/wiki/Maps_Terms_of_Use">Wikimedia</a>',
        minZoom: 1,
        maxZoom: 19
      }).addTo(map);
      // ^ More tile providers can be explored at https://leaflet-extras.github.io/leaflet-providers/preview/

      const LocationMarker = this.LocationMarker = L.Marker.extend({
        options: {
          location_title: '',
          location_description: '',
          location_category: '',
          location_id: '',
          unselectedIcon: null,
          selectedIcon: null,
        }
      });

      this.yellowIcon = new L.Icon({
        iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-yellow.png',
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      });
      this.greenIcon = new L.Icon({
        iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      });
      this.redIcon = new L.Icon({
        iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      });
      this.blueIcon = new L.Icon({
        iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      });
      // ^ More icons can be found at https://github.com/pointhi/leaflet-color-markers

      // Markers with icons inside from https://www.npmjs.com/package/leaflet.awesome-markers
      this.coffeeMarkerIcon = L.AwesomeMarkers.icon({
        icon: 'cafe',
        prefix: 'ion-md',
        markerColor: 'darkred',
        extraClasses: 'icon'
      });
      this.selectedCoffeeMarkerIcon = L.AwesomeMarkers.icon({
        icon: 'cafe',
        prefix: 'ion-md',
        markerColor: 'white',
        extraClasses: 'icon',
        iconColor: 'darkred',
      });
      this.shopMarkerIcon = L.AwesomeMarkers.icon({
        icon: 'shirt',
        prefix: 'ion-md',
        markerColor: 'blue',
        extraClasses: 'icon',
      });
      this.selectedShopMarkerIcon = L.AwesomeMarkers.icon({
        icon: 'shirt',
        prefix: 'ion-md',
        markerColor: 'white',
        extraClasses: 'icon',
        iconColor: 'blue',
      });
      this.barMarkerIcon = L.AwesomeMarkers.icon({
        icon: 'wine',
        prefix: 'ion-md',
        markerColor: 'green',
        extraClasses: 'icon'
      });
      this.selectedBarMarkerIcon = L.AwesomeMarkers.icon({
        icon: 'wine',
        prefix: 'ion-md',
        markerColor: 'white',
        extraClasses: 'icon',
        iconColor: 'green',
      });
      this.circleMarkerIcon = L.AwesomeMarkers.icon({
        icon: 'star',
        prefix: 'ion-md',
        markerColor: 'gray',
        extraClasses: 'icon',
        iconColor: 'white',
      });
      this.selectedCircleMarkerIcon = L.AwesomeMarkers.icon({
        icon: 'star',
        prefix: 'ion-md',
        markerColor: 'white',
        extraClasses: 'icon',
        iconColor: 'black',
      });

      //L.marker(this.getRandomLatLng(), {icon: this.circleMarkerIcon}).addTo(map);
      //L.marker(this.getRandomLatLng(), {icon: this.redIcon}).addTo(map);

      // Marker clusters from https://github.com/Leaflet/Leaflet.markercluster
      const CLUSTERS = {
        'cafe': L.markerClusterGroup({
          iconCreateFunction: function (cluster) {
            return L.divIcon({
              className: 'cluster-icon cluster-icon-darkred',
              html: `<i class="icon ion-md ion-md-cafe"></i> × <span class="number">${cluster.getChildCount()}</span>`,
              iconSize: [80, 40],
            });
          }
        }),
        'bar': L.markerClusterGroup({
          iconCreateFunction: function(cluster) {
            return L.divIcon({
              className: 'cluster-icon cluster-icon-green',
              html: `<i class="icon ion-md ion-md-wine"></i> × <span class="number">${cluster.getChildCount()}</span>`,
              iconSize: [80, 40],
            });
          }
        }),
        'shop': L.markerClusterGroup({
          iconCreateFunction: function(cluster) {
            return L.divIcon({
              className: 'cluster-icon cluster-icon-blue',
              html: `<i class="icon ion-md ion-md-shirt"></i> × <span class="number">${cluster.getChildCount()}</span>`,
              iconSize: [80, 40],
            });
          }
        }),
      };

      const MARKER_ICONS = {
        'cafe': {
          'unselected': this.coffeeMarkerIcon,
          'selected': this.selectedCoffeeMarkerIcon,
        },
        'bar': {
          'unselected': this.barMarkerIcon,
          'selected': this.selectedBarMarkerIcon,
        },
        'shop': {
          'unselected': this.shopMarkerIcon,
          'selected': this.selectedShopMarkerIcon,
        }
      };

      // add markers to the clusters
      locations.forEach(function(location) {
        CLUSTERS[location.category].addLayer(
          new LocationMarker(that.getRandomLatLng(map), {
            icon: MARKER_ICONS[location.category].unselected,
            location_id: location.id,
            location_title: location.title,
            location_description: location.description,
            location_category: location.category,
            unselectedIcon: MARKER_ICONS[location.category].unselected,
            selectedIcon: MARKER_ICONS[location.category].selected,
          }).on('click', that.markerOnClick.bind(that))
        )
      });

      // add clusters to the map
      map.addLayer(CLUSTERS.cafe);
      map.addLayer(CLUSTERS.bar);
      map.addLayer(CLUSTERS.shop);

      setTimeout(() => {
        map.invalidateSize(true); // <-- this is necessary to fix the quirky rendering
      }, 300);

    }
  }

  componentWillUnmount() {
    if (this.map) {
      this.map.remove();
      this.map = null;
    }
  }

  locateUser(e) {
    const map = this.map;
    console.log("Locating requested");
    map.on('locationfound', this.onLocationFound.bind(this));
    map.locate({setView: true, maxZoom: 16});
  }

  onLocationFound(e) {
    const map = this.map;
    console.log("Location found");
    let radius = e.accuracy;

    if (this.currentLocation) {
      map.removeLayer(this.currentLocation);
    }
    // if (this.approximationCircle) {
    //   map.removeLayer(this.approximationCircle);
    // }
    let currentLocation = new this.LocationMarker(e.latlng, {
      icon: this.circleMarkerIcon,
      location_id: null,
      location_title: "That's you!",
      location_description: 'Where do you want to go today?',
      unselectedIcon: this.circleMarkerIcon,
      selectedIcon: this.selectedCircleMarkerIcon,
    }).on('click', this.markerOnClick.bind(this)).addTo(map);

    currentLocation.fire('click', {latlng: e.latlng, target: currentLocation});

    console.log("You are within " + radius + " meters from this point");

    //let approximationCircle = L.circle(e.latlng, radius).addTo(map);

    map.setView(e.latlng, 16);
    map.invalidateSize(true); // <-- this is necessary to fix the quirky rendering

    this.currentLocation = currentLocation;
    //this.approximationCircle = approximationCircle;

    this.setState({
      user_latlng: e.latlng,
    })
  }

  getRandomLatLng() {
    const L = window.L;
    const map = this.map;
    var bounds = map.getBounds(),
      southWest = bounds.getSouthWest(),
      northEast = bounds.getNorthEast(),
      lngSpan = northEast.lng - southWest.lng,
      latSpan = northEast.lat - southWest.lat;

    return new L.LatLng(
      southWest.lat + latSpan * Math.random(),
      southWest.lng + lngSpan * Math.random());
  }

  markerOnClick(e) {
    const map = this.map;
    if (this.currentActiveMarker) {
      // unselect previous marker
      this.currentActiveMarker.setIcon(this.currentActiveMarker.options.unselectedIcon);
    }
    if (this.currentActiveMarker && this.currentActiveMarker === e.target) {
      // unselect current marker
      this.currentActiveMarker = null;
      this.setState({
        location_id: null,
        location_title: null,
        location_description: null,
        location_latlng: null,
      });
    } else {
      // select a new marker
      this.currentActiveMarker = e.target;
      this.currentActiveMarker.setIcon(this.currentActiveMarker.options.selectedIcon);

      map.setView(e.latlng);
      map.invalidateSize(true); // <-- this is necessary to fix the quirky rendering
      this.setState({
        location_id: this.currentActiveMarker.options.location_id,
        location_title: this.currentActiveMarker.options.location_title,
        location_description: this.currentActiveMarker.options.location_description,
        location_latlng: e.latlng,
      });
    }
  }
  centerPosition(e) {
    if (this.state.location_latlng) {
      const map = this.map;
      map.setView(this.state.location_latlng);
    }
  }
  goToLocationDetails(e) {
    window.location.href = `#/locations/${this.state.location_id}`;
  }
  render() {
    let distance = null;
    if (this.state.location_latlng && this.state.user_latlng) {
      distance = this.state.location_latlng.distanceTo(this.state.user_latlng).toFixed(0) / 1000;
    }
    return (
    <>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Map</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent padding>
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton onClick={this.locateUser.bind(this)}> <IonIcon name="locate"/> </IonFabButton>
        </IonFab>
        <div id="location_map_wrapper">
          <div id="location_map"/>
        </div>
      </IonContent>
      <IonFooter>{this.state.location_title &&
        <React.Fragment>
          <IonFab vertical="top" horizontal="end" slot="fixed">
            <IonFabButton onClick={this.centerPosition.bind(this)}> <IonIcon name="pin"/> </IonFabButton>
          </IonFab>
          <h3>{this.state.location_title} {distance > 0 && <small>({distance} km)</small>}</h3>
          <p>{this.state.location_description}</p>
          {this.state.location_id &&
            <IonButton color="primary" onClick={this.goToLocationDetails.bind(this)}>More info…</IonButton>
          }
        </React.Fragment>
      }
      </IonFooter>
    </>
    );
  }
}

export default LocationMap;
