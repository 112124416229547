import React, { Component } from "react";
import {
  IonList,
  IonIcon,
  IonItem,
  IonListHeader,
  IonLabel,
  IonDatetime
} from "@ionic/react";
import BasicPage from "../components/BasicPage";
import { ApolloProvider, Query } from "react-apollo";
import ApolloClient from "apollo-boost";
import gql from "graphql-tag";

const client = new ApolloClient({
  uri: "https://staging.ruhrkultur.jetzt/graphql/"
});

/**
 * sets the `title` and property hasMenu = true so that the menu for the side
 * drawer is displayed
 *
 * sets the `renderContent` propety to render the contents of the page
 */
class GraphExample extends Component {
  render() {
    return (
      <BasicPage
        title="Graph Example"
        hasMenu
        renderContent={history => {
          return (
            <ApolloProvider client={client}>
              <Query
                query={gql`
                  {
                    allTodos(first: 3) {
                      id
                      title
                      description
                      createdAt
                      updatedAt
                      __typename
                    }
                    allPersons(first: 3) {
                      id
                      name
                    }
                    allOrganizations(first: 3) {
                      id
                      name
                      description
                    }
                    allPlaces(first: 3) {
                      id
                      address {
                        id
                        streetAddress
                        addressCountry
                        postalCode
                      }
                      name
                    }
                  }
                `}
              >
                {({ loading, error, data }) => {
                  if (loading) return <p>Good things take time....</p>;
                  if (error) return <p>Something went wrong...</p>;

                  return (
                    <>
                      <IonList>
                        <IonListHeader color="secondary">
                          <IonLabel>Todos</IonLabel>
                        </IonListHeader>
                        {data.allTodos.map(item => (
                          <IonItem key={item.id}>
                            <IonIcon slot="start" name="checkbox" />
                            <IonLabel>
                              <h3>{item.title}</h3>
                              <p>{item.description}</p>
                            </IonLabel>
                            <IonDatetime
                              readonly
                              displayFormat="MMM DD, YYYY HH:mm	"
                              value={item.createdAt}
                            />
                          </IonItem>
                        ))}
                      </IonList>
                      <IonList>
                        <IonListHeader color="secondary">
                          <IonLabel>People</IonLabel>
                        </IonListHeader>
                        {data.allPersons.map(item => (
                          <IonItem key={item.id}>
                            <IonIcon slot="start" name="person" />
                            <IonLabel>
                              <h3>{item.name}</h3>
                            </IonLabel>
                          </IonItem>
                        ))}
                      </IonList>
                      <IonList>
                        <IonListHeader color="secondary">
                          <IonLabel>Organizations</IonLabel>
                        </IonListHeader>
                        {data.allOrganizations.map(item => (
                          <IonItem key={item.id}>
                            <IonIcon slot="start" name="briefcase" />
                            <IonLabel>
                              <h3>{item.name}</h3>
                              <p>{item.description}</p>
                            </IonLabel>
                          </IonItem>
                        ))}
                      </IonList>
                      <IonList>
                        <IonListHeader color="secondary">
                          <IonLabel>Places</IonLabel>
                        </IonListHeader>
                        {data.allPlaces.map(item => (
                          <IonItem key={item.id}>
                            <IonIcon slot="start" name="map" />
                            <IonLabel>
                              <h3>{item.name}</h3>
                              <p>{item.address.addressCountry}</p>
                            </IonLabel>
                          </IonItem>
                        ))}
                      </IonList>
                    </>
                  );
                }}
              </Query>
            </ApolloProvider>
          );
        }}
      />
    );
  }
}

export default GraphExample;
