import React, { Component } from "react";
import {
  IonItem,
  IonLabel,
  IonButton,
  IonDatetime,
  IonList
} from "@ionic/react";
import BasicPage from "../components/BasicPage";
import ItemService from "../shared/ItemService";

/**
 * sets the `title` and property hasMenu = false so  the menu for the side
 * drawer is NOT displayed.
 *
 * sets the `backAction` property so the back button appears
 *
 * sets the `renderContent` propety to render the contents of the page
 */
class ItemDetail extends Component {
  constructor(props) {
    super(props);
    this.itemService = new ItemService();
    this.state = {};
    this.id = parseInt(this.props.match.params.item_id, 10);
  }

  componentDidMount() {
    this.itemService.getItem(this.id).then(item => {
      this.setState(oldState => {
        // Important: read `oldState` instead of `this.state` when updating.
        return { item };
      });
    });
  }

  render() {
    return (
      <BasicPage
        title={`Details for Item #${this.id}`}
        backAction={e => {
          this.props.history.goBack();
        }}
        renderContent={history => {
          return (
            <>
              <IonList>
                <IonItem>
                  <IonLabel>Title</IonLabel>
                  <IonLabel slot="end" text-wrap>
                    {this.state.item
                      ? this.state.item.title
                      : "(title placeholder)"}
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>Description </IonLabel>
                  <IonLabel slot="end" text-wrap>
                    {this.state.item
                      ? this.state.item.description
                      : "(description placeholder)"}
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>Author </IonLabel>
                  <IonLabel slot="end" text-wrap>
                    {this.state.item
                      ? this.state.item.author
                      : "(author placeholder)"}
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>Created at </IonLabel>
                  <IonDatetime
                    disabled
                    displayFormat="MMM DD, YYYY HH:mm	"
                    value={this.state.item ? this.state.item.created_at : null}
                  />
                </IonItem>
                <IonItem lines="none">
                  <IonLabel>Updated at </IonLabel>
                  <IonDatetime
                    disabled
                    displayFormat="MMM DD, YYYY HH:mm	"
                    value={this.state.item ? this.state.item.updated_at : null}
                  />
                </IonItem>
              </IonList>
              <IonButton
                color="primary"
                onClick={e => {
                  e.preventDefault();
                  history.push(`/item-edit/${this.id}`);
                }}
              >
                Edit Item
              </IonButton>
              <IonButton
                color="secondary"
                onClick={e => {
                  e.preventDefault();
                  history.push("/item-list");
                }}
              >
                Cancel
              </IonButton>
              <IonButton
                color="danger"
                onClick={e => {
                  e.preventDefault();
                  this.itemService.deleteItem(this.id);
                  history.push(`/item-list`);
                }}
              >
                Delete
              </IonButton>
            </>
          );
        }}
      />
    );
  }
}

export default ItemDetail;
