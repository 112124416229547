import Configuration from "./Configuration";

class ItemService {
  constructor() {
    this.config = new Configuration();
  }

  async retrieveItems() {
    console.log("ItemService.retrieveItems()");
    return fetch(this.config.ITEM_COLLECTION_URL)
      .then(response => {
        if (!response.ok) {
          this.handleResponseError(response);
        }
        return response.json();
      })
      .then(json => {
        console.log("Retrieved items:", json);
        return json;
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  async getItem(itemId) {
    const itemLink = `${this.config.ITEM_COLLECTION_URL}/${itemId}/`;
    console.log("ItemService.getItem():", itemLink);
    return fetch(itemLink)
      .then(response => {
        if (!response.ok) {
          this.handleResponseError(response);
        }
        return response.json();
      })
      .then(item => {
        console.log("Retrieved item:", item);
        return item;
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  async createItem(newItem) {
    console.log("ItemService.createItem():", newItem);
    return fetch(this.config.ITEM_COLLECTION_URL, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(newItem)
    })
      .then(response => {
        if (!response.ok) {
          this.handleResponseError(response);
        }
        return response.json();
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  async deleteItem(itemId) {
    const itemLink = `${this.config.ITEM_COLLECTION_URL}/${itemId}/`;
    console.log("ItemService.deleteItem():", itemLink);
    return fetch(itemLink, {
      method: "DELETE",
      mode: "cors"
    })
      .then(response => {
        if (!response.ok) {
          this.handleResponseError(response);
        }
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  async updateItem(item) {
    console.log("ItemService.updateItem():", item);
    const itemLink = `${this.config.ITEM_COLLECTION_URL}/${item.id}/`;
    return fetch(itemLink, {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(item)
    })
      .then(response => {
        if (!response.ok) {
          this.handleResponseError(response);
        }
        return response.json();
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }

  handleError(error) {
    console.log(error.message);
  }
}

export default ItemService;
