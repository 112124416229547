class Validator {
  validateInputs(inputData) {
    let errorMsg = "";
    if (!inputData.title) {
      errorMsg += "Please enter title of this item.\n";
    }
    if (!inputData.description) {
      errorMsg += "Please enter description of this item.\n";
    }
    if (!inputData.author) {
      errorMsg += "Please enter an author for this item.\n";
    }
    if (errorMsg.length === 0) {
      return true;
    } else {
      alert(errorMsg);
      return false;
    }
  }
}

export default Validator;
