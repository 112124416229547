import React, { Component } from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import { IonApp, IonSplitPane, IonPage } from "@ionic/react";
import { Redirect, Switch } from "react-router";
import "./App.css";

import Menu from "./components/Menu";
import ItemList from "./pages/ItemList";
import ItemDetail from "./pages/ItemDetail";
import ItemNew from "./pages/ItemNew";
import ItemEdit from "./pages/ItemEdit";
import GraphExample from "./pages/GraphExample";
import LocationMap from "./pages/LocationMap";
import BarcodeScanner from "./pages/BarcodeScanner";
import BookletPage from "./pages/BookletPage";
import PageNotFound from "./pages/PageNotFound";

/* Core CSS required for Ionic components to work properly */
import "@ionic/core/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/core/css/normalize.css";
import "@ionic/core/css/structure.css";
import "@ionic/core/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/core/css/padding.css";
import "@ionic/core/css/float-elements.css";
import "@ionic/core/css/text-alignment.css";
import "@ionic/core/css/text-transformation.css";
import "@ionic/core/css/flex-utils.css";
import "@ionic/core/css/display.css";

const appPages = [
  {
    title: "Items",
    url: "/item-list",
    icon: "list"
  },
  {
    title: "Graph Example",
    url: "/graph-example",
    icon: "globe"
  },
  {
    title: "Map",
    url: "/map",
    icon: "map"
  },
  {
    title: "Barcode Scanner",
    url: "/scan",
    icon: "camera"
  }
];

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <IonApp>
            <IonSplitPane contentId="main">
              <Menu appPages={appPages} />
              <IonPage id="main">
                <Switch>
                  <Redirect exact path="/" to="/item-list" />
                  <Route exact path="/item-list" component={ItemList} />
                  <Route exact path="/item-new" component={ItemNew} />
                  <Route
                    exact
                    path="/item-edit/:item_id"
                    component={ItemEdit}
                  />
                  <Route
                    exact
                    path="/item-detail/:item_id"
                    component={ItemDetail}
                  />
                  <Route exact path="/graph-example" component={GraphExample} />
                  <Route exact path="/map" component={LocationMap} />
                  <Route exact path="/scan" component={BarcodeScanner} />
                  <Route exact path="/go/:page_id" component={BookletPage} />
                  <Route path="/" component={PageNotFound} />
                </Switch>
              </IonPage>
            </IonSplitPane>
          </IonApp>
        </div>
      </Router>
    );
  }
}

export default App;
