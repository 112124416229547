import React, { Component } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonButton
} from "@ionic/react";
import BasicPage from "../components/BasicPage";

import Quagga from "quagga";

function pad(str, max) {
  str = str.toString();
  return str.length < max ? pad("0" + str, max) : str;
}

/**
  Barcode scanner based on the example from https://github.com/serratus/quaggaJS/blob/master/example/live_w_locator.js
  Scans the EAN-8 barcodes and redirects to a page related to the number scanned.
 */
class BarcodeScanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      short_code: null,
      code_in_progress: "00777"
    };
    this.baseState = JSON.parse(JSON.stringify(this.state));
  }
  componentDidMount() {
    this.setState(this.baseState);
  }
  barcodeScannerInit() {
    Quagga.init(
      {
        inputStream: {
          type: "LiveStream",
          constraints: {
            width: { min: 640 },
            height: { min: 480 },
            facingMode: "environment",
            aspectRatio: { min: 1, max: 2 }
          },
          target: "#barcode_scanner"
        },
        locator: {
          patchSize: "medium",
          halfSample: true
        },
        numOfWorkers: 2,
        frequency: 10,
        decoder: {
          readers: [
            {
              format: "ean_8_reader",
              config: {}
            }
          ],
          multiple: false
        },
        locate: true
      },
      function(err) {
        if (err) {
          console.log(err);
          return;
        }
        console.log("Barcode scanner initialization finished. Ready to start");
        Quagga.start();
      }
    );
  }
  attachEvents() {
    let that = this;
    Quagga.onProcessed(function(result) {
      var drawingCtx = Quagga.canvas.ctx.overlay,
        drawingCanvas = Quagga.canvas.dom.overlay;

      if (result) {
        if (result.boxes) {
          drawingCtx.clearRect(
            0,
            0,
            parseInt(drawingCanvas.getAttribute("width")),
            parseInt(drawingCanvas.getAttribute("height"))
          );
          result.boxes
            .filter(function(box) {
              return box !== result.box;
            })
            .forEach(function(box) {
              Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                color: "green",
                lineWidth: 2
              });
            });
        }

        if (result.box) {
          Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
            color: "#00F",
            lineWidth: 2
          });
        }

        if (result.codeResult && result.codeResult.code) {
          Quagga.ImageDebug.drawPath(
            result.line,
            { x: "x", y: "y" },
            drawingCtx,
            { color: "red", lineWidth: 3 }
          );
        }
      }
    });

    Quagga.onDetected(function(result) {
      let full_code = result.codeResult.code;
      console.log("quagga result", result);
      console.log("full code", full_code);
      let short_code = full_code.slice(2, -1);
      console.log("short code", short_code);
      that.setDetected(short_code);
    });
  }
  setDetected(short_code) {
    this.stopScanner();
    if (this.state.short_code !== short_code) {
      this.setState({
        short_code: short_code,
        code_in_progress: short_code
      });
      window.location.href = `#/go/${short_code}`;
    }
  }
  updateCode(e) {
    console.log("Updated: ", e.target.value);
    this.setState({ code_in_progress: e.target.value });
  }
  searchCode(e) {
    e.preventDefault();
    let short_code = pad(this.state.code_in_progress, 5);
    this.setState({ short_code: short_code });
    window.location.href = `#/go/${short_code}`;
  }
  startScanner() {
    console.log("Scanner started");
    this.barcodeScannerInit();
    this.attachEvents();
  }
  stopScanner() {
    console.log("Scanner stopped");
    Quagga.offProcessed();
    Quagga.offDetected();
    Quagga.stop();
  }
  componentWillUnmount() {
    this.setState(this.baseState);
    console.log("Barcode scanner unmounted");
  }
  render() {
    return (
      <BasicPage
        title="Scan Barcode"
        hasMenu
        renderContent={history => {
          return (
            <>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonItem>
                      <IonLabel>Enter code:</IonLabel>
                      <IonInput
                        type="number"
                        value={this.state.code_in_progress}
                        onInput={this.updateCode.bind(this)}
                      />
                    </IonItem>
                  </IonCol>
                  <IonCol>
                    <IonButton
                      onClick={this.searchCode.bind(this)}
                      expand="block"
                    >
                      Search by code
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <br />
              <IonButton onClick={this.startScanner.bind(this)}>
                Scan barcode
              </IonButton>
              <IonButton onClick={this.stopScanner.bind(this)}>
                Stop scanning
              </IonButton>
              <div id="barcode_scanner_wrapper">
                <div id="barcode_scanner" />
              </div>
            </>
          );
        }}
      />
    );
  }
}

export default BarcodeScanner;
