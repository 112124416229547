import React, { Component } from "react";
import { IonButton, IonItem, IonInput, IonLabel } from "@ionic/react";
import BasicPage from "../components/BasicPage";
import "../App.css";
import Validator from "../shared/Validator";
import ItemService from "../shared/ItemService";

/**
 * sets the `title` and property hasMenu = false so  the menu for the side
 * drawer is NOT displayed.
 *
 * sets the `backAction` property so the back button appears
 *
 * sets the `renderContent` propety to render the contents of the page
 */
class ItemNew extends Component {
  constructor(props) {
    super(props);
    this.itemService = new ItemService();
    this.validator = new Validator();
    this.handleInputChange = this.handleInputChange.bind(this);
    this.state = {
      title: "",
      description: "",
      author: ""
    };
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    if (name !== "") {
      this.setState({
        [name]: value
      });
    }
  }

  render() {
    return (
      <BasicPage
        title="New Item"
        backAction={e => {
          this.props.history.goBack();
        }}
        renderContent={history => {
          return (
            <>
              <IonItem>
                <IonLabel>Title</IonLabel>
                <IonInput
                  slot="end"
                  clearInput
                  name="title"
                  placeholder="Enter some title"
                  required
                  onIonChange={this.handleInputChange}
                  maxLength="40"
                />
              </IonItem>
              <IonItem>
                <IonLabel>Description</IonLabel>
                <IonInput
                  slot="end"
                  clearInput
                  name="description"
                  placeholder="Enter some description"
                  required
                  onIonChange={this.handleInputChange}
                  maxLength="40"
                />
              </IonItem>
              <IonItem lines="none">
                <IonLabel>Author</IonLabel>
                <IonInput
                  slot="end"
                  clearInput
                  name="author"
                  placeholder="Enter a valid author id"
                  required
                  onIonChange={this.handleInputChange}
                />
              </IonItem>
              <IonButton
                color="primary"
                onClick={e => {
                  e.preventDefault();
                  this.itemService.createItem(this.state);
                  history.push(`/item-list`);
                }}
              >
                Create
              </IonButton>
              <IonButton
                color="secondary"
                onClick={e => {
                  e.preventDefault();
                  history.push(`/item-list`);
                }}
              >
                Cancel
              </IonButton>{" "}
            </>
          );
        }}
      />
    );
  }
}

export default ItemNew;
