import React from "react";
import { IonItem, IonLabel } from "@ionic/react";
import BasicPage from "../components/BasicPage";

/**
 * sets the `title` and property hasMenu = false so  the menu for the side
 * drawer is NOT displayed.
 *
 * sets the `renderContent` propety to render the contents of the page
 */
class PageNotFound extends React.Component {
  render() {
    return (
      <BasicPage
        title="Page Not Found"
        renderContent={history => {
          return (
            <>
              <IonItem lines="none">
                <IonLabel>Sorry, page not found.</IonLabel>
              </IonItem>
            </>
          );
        }}
      />
    );
  }
}

export default PageNotFound;
